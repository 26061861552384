
@font-face {
  font-family: "NunitoSans";
  src: url("./assets/fonts/NunitoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NunitoSans";
  src: url("./assets/fonts/NunitoSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "NunitoSans";
  src: url("./assets/fonts/NunitoSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "NunitoSans";
  src: url("./assets/fonts/NunitoSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

body,
#root,
.vh {
  height: 100vh;
  overflow-x: hidden;
}

.color-change-2x {
  animation: color-change-2x 0.5s linear infinite alternate both;
}

@keyframes color-change-2x {
  0% {
    background: #f6f8fb;
  }
  100% {
    background: #e4eaf3;
  }
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
